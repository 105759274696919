import React from 'react';
import HelpBlock from "../components/sections/Help";
import {Link} from "react-router-dom";
import {linkTo} from "../utils/InternalLink";
import PageTitle from "../components/sections/partials/PageTitle";
import OwnSells from "../components/product/OwnSells";
import StatCard from "../components/sections/StatCard";
import Alert from "../components/elements/Alert";

const PrivateHome = () => {
  return (
    <>
        <PageTitle mainTitle="Bem-vindo ao Marqet!">
            <ul className="nk-block-tools g-3">
                {/*<li className="nk-block-tools-opt">*/}
                {/*    <Link to="/buy" className="btn btn-outline-primary">*/}
                {/*        <em className="icon ni ni-reports" /><*/}
                {/*        span>Quero Comprar</span>*/}
                {/*    </Link>*/}
                {/*</li>*/}
                <li className="nk-block-tools-opt">
                    <Link to={linkTo.newSell()} className="btn btn-success">
                        <em className="icon ni ni-reports" /><
                        span>Novo Anúncio</span>
                    </Link>
                </li>
            </ul>
        </PageTitle>
        <Alert title="Pré-lançamento" description="Ainda não lançamos oficialmente o Marqet. No entretanto, pode ir colocando os seus produtos para venda." type="light"/>
        <div className="nk-block">
            <div className="row g-gs">
                <div className="col-md-8 col-sm-12">
                    <OwnSells />
                </div>
                <div className="col-md-4 col-sm-12">
                    <StatCard title="Vendas" value="0€" />
                    <StatCard title="Comissões Pagas" value="0€" style="yellow" />
                </div>
                <div className="col-md-8 col-sm-12">
                    <HelpBlock />
                </div>
            </div>
        </div>
    </>
  );
}

export default PrivateHome;