import React, {useEffect} from 'react';
import {Link} from "react-router-dom";

import FactoryImage from "../assets/images/homepage/factory.svg";
import BusinessImage from "../assets/images/homepage/business.svg";
import BookingImage from "../assets/images/homepage/booking.svg";
import '../assets/scss/homepage.scss';
import {linkTo} from "../utils/InternalLink";

const PublicHome = () => {
    // remove the dark mode in this page
    useEffect(() => {
        document.body.classList.remove("dark-mode")
        // Specify how to clean up after this effect:
        return () => {
            document.body.classList.add("dark-mode")
        };
    });

    return (
        <>
            <div id="page-container">
                <div className="hero">
                    <div className="container">
                        <header className="py-5">
                            <div className="row">
                                <div className="col-md-4 text-center text-md-left mb-3 mb-md-0 homepage-logo-size text-yellow">
                                    Marqet
                                </div>
                                <div className="col-md-8">
                                    <nav className="nav nav-header justify-content-center justify-content-md-end">
                                        <div className="nav-link active">Home</div>
                                        <Link to="/login" className="nav-link">
                                            Login
                                        </Link>
                                        <Link to="/register" className="nav-link">
                                            Registar
                                        </Link>
                                    </nav>
                                </div>
                            </div>
                        </header>
                        <div className="row justify-content-center py-5">
                            <div className="col-lg-12">
                                <div className="py-5 text-center">
                                    <h1 className="font-weight-bold text-white mb-4">
                                        O mercado de compra e venda para <div className="text-yellow"> empresas</div>
                                    </h1>
                                    <p className="lead font-weight-normal text-white-75 mx-lg-5 mb-5">
                                        Compre e venda num ambiente profissional, com toda a segurança e comodidade.
                                    </p>
                                    <Link to="/register" className="btn btn-success text-uppercase font-weight-bold py-3 px-4">
                                        Criar conta
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="bg-white py-5">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div
                                className="col-lg-5 d-flex align-items-center justify-content-center justify-content-lg-end py-5">
                                <div className="pr-lg-4">
                                    <img className="img-fluid homepage-img" src={BookingImage} alt="A factory"/>
                                </div>
                            </div>
                            <div
                                className="col-lg-5 d-flex align-items-center justify-content-center justify-content-lg-start py-5">
                                <div className="pl-lg-4">
                                    <h2 className="font-weight-bold text-yellow">
                                        Ambiente Profissional
                                    </h2>
                                    <p className="lead font-weight-normal text-muted mb-4">
                                        Não perca tempo com negócios que não vão a lado nenhum.
                                    </p>
                                    <ul className="fa-ul text-muted">
                                        <li>
                                            <i className="icon ni ni-check mr-1 text-success" /> Empresas verificadas
                                        </li>
                                        <li>
                                            <i className="icon ni ni-check mr-1 text-success" /> Produtos verificados
                                        </li>
                                        <li>
                                            <i className="icon ni ni-check mr-1 text-success" /> Máxima discrição
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/*<div className="bg-primary-light py-5">*/}
                {/*    <div className="container">*/}
                {/*        <div className="row text-center">*/}
                {/*            <div className="col-sm-6 col-lg-3 py-5">*/}
                {/*                <div className="display-4">*/}
                {/*                    350*/}
                {/*                </div>*/}
                {/*                <div className="text-uppercase text-muted font-weight-bold">Clients</div>*/}
                {/*            </div>*/}
                {/*            <div className="col-sm-6 col-lg-3 py-5">*/}
                {/*                <div className="display-4">*/}
                {/*                    580*/}
                {/*                </div>*/}
                {/*                <div className="text-uppercase text-muted font-weight-bold">Projects</div>*/}
                {/*            </div>*/}
                {/*            <div className="col-sm-6 col-lg-3 py-5">*/}
                {/*                <div className="display-4">*/}
                {/*                    1.5k*/}
                {/*                </div>*/}
                {/*                <div className="text-uppercase text-muted font-weight-bold">Sales</div>*/}
                {/*            </div>*/}
                {/*            <div className="col-sm-6 col-lg-3 py-5">*/}
                {/*                <div className="display-4">*/}
                {/*                    35*/}
                {/*                </div>*/}
                {/*                <div className="text-uppercase text-muted font-weight-bold">Servers</div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}

                <div className="bg-dark py-5">
                    <div className="container text-center py-5">
                        <h2 className="h1 font-weight-bold text-white mb-3">
                            Quer comprar ou vender?
                        </h2>
                        <p className="lead font-weight-normal text-white-75 mx-lg-5 mb-5">
                             Diga-nos.
                            <b className="text-yellow"> Nós tratamos do resto!</b>
                        </p>
                        <Link to="/register" className="btn btn-success text-uppercase font-weight-bold py-3 px-4">
                            Criar conta
                        </Link>
                    </div>
                </div>

                <div className="bg-white py-5">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div
                                className="col-lg-5 order-lg-1 d-flex align-items-center justify-content-center justify-content-lg-start py-5">
                                <div className="pl-lg-4">
                                    <img className="img-fluid homepage-img" src={BusinessImage} alt="Some boxes" />
                                </div>
                            </div>
                            <div
                                className="col-lg-5 order-lg-0 d-flex align-items-center justify-content-center justify-content-lg-end py-5">
                                <div className="pr-lg-4">
                                    <h2 className="font-weight-bold text-yellow">
                                        Proximidade
                                    </h2>
                                    <p className="lead font-weight-normal text-muted mb-4">
                                        Só assim é possível ligar as empresas num mercado único, permitindo-as prosperar. Precisa de ajuda? Estamos aqui para si!
                                    </p>
                                    <ul className="fa-ul text-muted">
                                        <li>
                                            <i className="icon ni ni-check mr-1 text-success" /> Seguimos e gerimos todo o processo de compra e venda
                                        </li>
                                        <li>
                                            <i className="icon ni ni-check mr-1 text-success" /> Encontramos o comprador para os seus produtos
                                        </li>
                                        <li>
                                            <i className="icon ni ni-check mr-1 text-success" /> Gestor de conta sempre disponível
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="bg-white py-5">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div
                                className="col-lg-5 d-flex align-items-center justify-content-center justify-content-lg-end py-5">
                                <div className="pr-lg-4">
                                    <img className="img-fluid homepage-img" src={FactoryImage}  alt="A factory"/>
                                </div>
                            </div>
                            <div
                                className="col-lg-5 d-flex align-items-center justify-content-center justify-content-lg-start py-5">
                                <div className="pl-lg-4">
                                    <h2 className="font-weight-bold text-yellow">
                                        Comodidade
                                    </h2>
                                    <p className="lead font-weight-normal text-muted mb-4">
                                        Precisa de vender aquela máquina que já não usa? E comprar uma empilhadora?
                                        Está no sítio certo!
                                    </p>
                                    <ul className="fa-ul text-muted">
                                        <li>
                                            <i className="icon ni ni-check mr-1 text-success" /> Tudo num só lugar
                                        </li>
                                        <li>
                                            <i className="icon ni ni-check mr-1 text-success" /> Avaliamos o preço justo
                                        </li>
                                        <li>
                                            <i className="icon ni ni-check mr-1 text-success" /> Procuramos por si
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <footer className="bg-primary-dark py-5">
                    <div className="container text-center py-5">
                        <h2 className="h1 font-weight-bold text-white mb-3">
                            Crie uma conta. É grátis.
                        </h2>
                        <p className="lead font-weight-normal text-white-75 mx-lg-5 mb-5">
                            Para sua segurança, todas as contas são verificadas <i className="icon ni ni-check-circle-fill mr-1 text-green" />
                        </p>
                        <Link to="/register" className="btn btn-success text-uppercase font-weight-bold py-3 px-4">
                            Criar conta
                        </Link>
                        <hr className="border-dark my-5 " />
                        <div className="row">
                            <div className="col-md-4 text-center text-md-left mb-3 mb-md-0 h3 text-yellow">
                                Marqet
                            </div>
                            <div className="col-md-8">
                                <nav className="nav nav-header justify-content-center justify-content-md-end">
                                    <Link className="nav-link" to="/contact">
                                        Contacto
                                    </Link>
                                    <Link to={linkTo.termsAndConditions()} className="nav-link">
                                        Termos &amp; Condições
                                    </Link>
                                    <Link to={linkTo.privacyPolicy()} className="nav-link">
                                        Política de Privacidade
                                    </Link>
                                    <a className="nav-link" href="https://www.livroreclamacoes.pt/inicio" target="_blank" rel="noopener noreferrer">Livro de Reclamações</a>
                                </nav>
                            </div>
                        </div>

                        <p className="text-white-75 mb-0 text-md-left text-sm-right">
                            Um produto <a className="bittencookie" href="https://bittencookie.pt/">Bitten Cookie</a>
                        </p>
                    </div>
                </footer>
            </div>
        </>
    );
}

export default PublicHome;