import React from 'react';
import TextOnly from "../components/TextOnly";

const Contact = () => {
    return (
        <TextOnly
            title="Contactos"
        >
            <p>
                E-mail: <a href="mailto:suporte@marqet.pt">suporte@marqet.pt</a>
            </p>
        </TextOnly>
    );
}

export default Contact;
