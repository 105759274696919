import React from 'react';
import Header from './partials/Header';
import Footer from './partials/Footer';
import Aside from "./partials/Aside";

const LayoutDefault = ({ children }) => {
    const aside = false;

    return (
        <div className="nk-app-root">
            <div className="nk-main ">
                <div className="nk-wrap ">
                    <Header />
                    <div className="nk-content ">
                        <div className="container wide-xl">
                            <div className="nk-content-inner">
                                {aside && <Aside /> }
                                <div className="nk-content-body">
                                    <div className="nk-content-wrap">
                                        {children}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </div>
    );
}

export default LayoutDefault;