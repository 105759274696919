import React, {useState, useEffect} from 'react';
import {Link, useHistory} from "react-router-dom";
import {ErrorMessage, Field, Form, Formik, useFormikContext} from 'formik';
import * as Yup from 'yup';
import {CustomDropdown, FormField, TextEditor} from "../../components/elements/form/FormField";
import HelpBlock from "../../components/sections/Help";
import S3Dropzone from "../../components/elements/Dropzone";
import {getFee, getPlaces, getTags, send_new_sell} from "../../api";
import {linkTo} from "../../utils/InternalLink";
import PageTitle from "../../components/sections/partials/PageTitle";

const Fee = () => {
    const [original_fee, setOriginalFee] = useState(0);
    const [fee, setFee] = useState(0);
    const [desconto, setDesconto] = useState(false);

    const { values } = useFormikContext();
    const price = values.price;

    useEffect(() => {
        const fetchData = async () => {
            const maybe_fee = await getFee(price);

            const original_fee = maybe_fee.original_fee;
            const atual_fee = maybe_fee.actual_fee;

            setDesconto(original_fee.fee_euros !== atual_fee.fee_euros);
            setFee(maybe_fee.actual_fee.fee_euros);
            setOriginalFee(maybe_fee.original_fee.fee_euros);
        };

        fetchData();
    }, [price]);

    return (
            <div className="form-group">
                <div className="form-label-group">
                    <label className="form-label">Comissão Marqet
                        <div className="nk-kycfm-note">
                            <em className="icon ni ni-info-fill"/>
                            <p>O que a sua empresa vai pagar ao Marqet</p>
                        </div>
                    </label>
                </div>
                <div className="row">
                    <div className="col-md-8">
                        <div className="form-control-wrap fee">
                            <div className="form-text-hint"><span className="overline-title">€ + IVA</span></div>
                            <div className="form-control form-control-lg">
                                { desconto && <del className="discount">{original_fee}</del>}
                                <span className="fee"> {fee}</span>
                            </div>
                        </div>
                    </div>
                </div>
                { desconto &&
                    <>
                        <div className="nk-kycfm-note text-success">
                            <em className="icon ni ni-check-circle-fill" />
                            <p className="">Aplicámos um desconto de 50% na comissão!</p>
                        </div>
                    </>
                }
            </div>
    );
};

const SellSection = ({number, title, subtitle, children}) => {
    return (
    <>
        <div className="nk-kycfm-head">
            <div className="nk-kycfm-count">{number}</div>
            <div className="nk-kycfm-title">
                <h5 className="title">{title}</h5>
                {subtitle && <p className="sub-title">{subtitle}</p> }
            </div>
        </div>
        <div className="nk-kycfm-content">
            {children}
        </div>
    </>
    );
};

const NewSell = () => {
    let history = useHistory();
    const [showError, setShowError] = useState(false);
    const [concelhos, setConcelhos] = useState([]);
    const [tags, setTags] = useState([]);

    const productConditionOptions = [
        {value: "New", label: "Novo"},
        {value: "Used", label: "Usado"}
    ];

    useEffect(() => {
        const fetchData = async () => {
            const maybe_concelhos = await getPlaces();
            setConcelhos(maybe_concelhos.map(x => {return { value: x.id, label: x.place} }));
        };

        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            const maybe_tags = await getTags();
            setTags(maybe_tags.map(x => {return { value: x.id, label: x.name} }));
        };

        fetchData();
    }, []);


    const validate = (values) => {
        const errors = {};
        if ( values.email &&
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
        ) {
            errors.email = 'Endereço de e-mail inválido';
        }
        return errors;
    }

    return (
        <>
            <PageTitle mainTitle="Novo anúncio" description="Precisa de ajuda?" />
            <Formik
                initialValues={{
                    title: '',
                    description: '',
                    place: '',
                    price: 10,
                    condition: '',
                    tags: [],
                    photos: [],
                    agreement: false,
                    verify: false
                }}
                // validate={validate}
                validationSchema={Yup.object({
                    title: Yup.string()
                        .min(1, "Tem que ter, pelo menos, 1 caracteres")
                        .max(600, 'Não pode ter mais que 600 caracteres')
                        .required('Obrigatório'),
                    description: Yup.string()
                        .min(30, "Tem que ter, pelo menos, 30 caracteres")
                        .max(1800, 'Não pode ter mais que 1800 caracteres')
                        .required('Obrigatório'),
                    place: Yup.number().required("Obrigatório"),
                    price: Yup.number()
                        .min(10, 'O valor mínimo são 10€ + IVA')
                        .max(1000000, 'Se pretender vender algo acima deste valor, entre em contacto connosco')
                        .required('Obrigatório'),
                    condition: Yup.string().oneOf(['New', 'Used']).required('Obrigatório'),
                    tags: Yup.array(Yup.number()).min(1, 'Deve adicionar, pelo menos, uma categoria').max(10, 'Tem categorias a mais! Máx: 10'),
                    photos: Yup.array(Yup.string()).min(3, 'Deve adicionar, pelo menos, três fotos').max(10, 'Tem fotos a mais! Máx: 10'),
                    agreement: Yup.boolean()
                        .required('Obrigatório')
                        .oneOf([true], 'É necessário aceitar os nossos termos e condições.'),
                    verify: Yup.boolean()
                        .required('Obrigatório')
                        .oneOf([true], 'É necessário aceitar.'),
                })}
                onSubmit={async (values, { setSubmitting }) => {
                    try {
                        console.log(values);
                        let answer = await send_new_sell(values);
                        setSubmitting(false);
                        history.push(linkTo.viewSell(answer.id));
                    } catch(e) {
                        // todo: handle error
                        setSubmitting(false);
                        setShowError(true);
                    }
                }}
            >
                {({ isSubmitting, values, setFieldValue }) => (
            <Form>
            <div className="nk-block">
                <div className="row g-gs">
                    <div className="col-sm-8">
                        <div className="nk-block">
                            <div className="card card-bordered">
                                <div className="nk-kycfm">
                                    <SellSection number="01" title="Detalhes do Produto">
                                        <div className="nk-kycfm-note text-warning">
                                            <em className="icon ni ni-caution" />
                                            <p className="">Preencha com o máximo de detalhe. Não poderá alterar esta informação depois do anúncio estar publicado.</p>
                                        </div>
                                        <div className="row g-4">
                                            <div className="col-md-12">
                                                <FormField
                                                    id="title"
                                                    type="text"
                                                    title="Título"
                                                    placeholder="Um bom título é breve e conciso..."
                                                />
                                            </div>
                                            <div className="col-md-12">
                                                <TextEditor
                                                    id="description"
                                                    title="Descrição"
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <CustomDropdown
                                                    id="place"
                                                    title="Localização"
                                                    options={concelhos}
                                                    isSearchable
                                                    placeholder={"Indique o concelho onde o produto se encontra"}
                                                    noOptionsMessage={() => "Pesquise um concelho"}
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <CustomDropdown
                                                    id="condition"
                                                    title="Estado"
                                                    options={productConditionOptions}
                                                    isSearchable
                                                    placeholder={"Indique o estado do produto"}
                                                    noOptionsMessage={() => ""}
                                                />
                                            </div>
                                        </div>
                                    </SellSection>
                                    <SellSection number="02" title="Informação" subtitle="Adicione informação para tornar o produto mais fácil de encontrar">
                                        <div className="row g-4">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <div className="form-control-group">
                                                        <CustomDropdown
                                                            id="tags"
                                                            title="Categorias"
                                                            options={tags}
                                                            isSearchable
                                                            isMulti
                                                            placeholder={"Procure aqui..."}
                                                            noOptionsMessage={() => "Não disponível"}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </SellSection>
                                    <SellSection number="03" title="Fotografias" subtitle="Ao obrigar todos os anúncios a terem fotografia estamos a melhorar a qualidade do conteúdo">
                                        <div className="row g-4">
                                            <div className="col-md-6">
                                                <div className="nk-kycfm-upload">
                                                    <h6 className="title nk-kycfm-upload-title">Forneça, pelo menos, 3 fotografias</h6>
                                                    <div className="row align-items-center">
                                                        <div className="col-sm-12">
                                                            <S3Dropzone fieldName="photos" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <h6 className="title">Por favor garanta que todas as fotografias:</h6>
                                                <ul className="list list-sm list-checked">
                                                    <li>Mostram o produto e o seu estado de conservação.</li>
                                                    <li>Estão devidamente focadas.</li>
                                                    <li>Possuem a luminosidade correta e adequada.</li>
                                                    <li>Estão no formato *.jpeg ou *.png.</li>
                                                    <li>Têm menos que 3MB.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </SellSection>
                                    <SellSection number="04" title="Preço & Comissão">
                                        <div className="row g-4">
                                            <div className="col-md-4">
                                                <FormField
                                                    id="price"
                                                    type="number"
                                                    title="Preço de Venda"
                                                    subtitle="O que o comprador lhe vai pagar"
                                                    placeholder="0"
                                                    rightSide="€ + IVA"
                                                />
                                            </div>
                                            <div className="offset-md-2 col-md-6">
                                                <Fee />
                                            </div>
                                        </div>
                                    </SellSection>
                                    <div className="nk-kycfm-footer">
                                        <div className="form-group">
                                            <div className="custom-control custom-control-xs custom-checkbox">
                                                <Field type="checkbox" name="agreement" className="custom-control-input" id="checkbox" />
                                                <label className="custom-control-label" htmlFor="checkbox">
                                                    Li e concordo com os
                                                    <Link to={linkTo.termsAndConditions()} target="_blank"> Termos &amp; Condições</Link>
                                                </label>
                                                <ErrorMessage name="agreement" component="div" className="invalid" />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="custom-control custom-control-xs custom-checkbox">
                                                <Field type="checkbox" name="verify" className="custom-control-input" id="info-assure" />
                                                <label className="custom-control-label" htmlFor="info-assure">Toda a informação aqui descrita é verdadeira e corresponde ao real estado do produto.</label>
                                                <ErrorMessage name="verify" component="div" className="invalid" />
                                            </div>
                                        </div>
                                        <div className="nk-kycfm-action pt-2">
                                            <button disabled={isSubmitting} type="submit" className="btn btn-lg btn-success">Vender!</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <HelpBlock vertical />
                    </div>
                </div>
            </div></Form>)}
            </Formik>
        </>
    );
}

export default NewSell;
