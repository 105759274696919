import React from 'react';
import {Link} from "react-router-dom";
import WarningImage from '../assets/images/undraw_Taken_re_yn20.svg';
import {linkTo} from "../utils/InternalLink";

const Page404 = () => {
    return (
        <div className="nk-app-root">
            <div className="nk-main ">
                <div className="nk-wrap nk-wrap-nosidebar">
                    <div className="nk-content ">
                        <div className="nk-block nk-block-middle wide-md mx-auto">
                            <div className="nk-block-content nk-error-ld text-center">
                                <img className="nk-error-gfx" src={WarningImage} alt="404" />
                                    <div className="wide-xs mx-auto">
                                        <h3 className="nk-error-title">Oops! Página não encontrada</h3>
                                        <p className="nk-error-text">
                                            Se pensa que ocorreu um problema com o conteúdo que tentou aceder, por favor entre em contacto connosco.
                                        </p>
                                        <Link to={linkTo.mainDashBoard()} className="btn btn-lg btn-primary mt-2"> Voltar</Link>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Page404;
