export const linkTo = {
    homepage: () => '/',
    mainDashBoard: () => '/dashboard',

    login: () => '/login',
    logout: () => '/logout',
    register: () => '/register',

    contact: () => '/contact',
    termsAndConditions: () => '/terms',
    privacyPolicy: () => '/privacy',

    // Sell
    newSell: () => '/sell/new',
    viewAllSell: () => '/sell/',
    viewSell: (id) => id !== undefined ? `/sell/${id}` : '/sell/:id',

    // Buy
    viewAllBuy: () => '/buy',

    // Rent
    viewAllRent: () => '/rent',

    // Help
    helpCenter: () => '/help',
    helpArticleAccountVerification: () => '/help/account-verification',
    helpArticleProductVerification: () => '/help/product-verification',
    helpArticleMarqetExplained: () => '/help/marqet-explained',
}