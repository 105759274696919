import React from 'react';
import { Switch } from 'react-router-dom';
import AppRoute from './utils/AppRoute';
import PrivateRoute from "./utils/PrivateRoute";
import PublicWhenIncognitoRoute from "./utils/PublicWhenIncognitoRoute";

// Layouts
import LayoutDefault from './layouts/LayoutDefault';
import LayoutFullPageFooterOnly from './layouts/LayoutFullPageFooterOnly';

// Views 
import PrivateHome from './pages/PrivateHome';
import Page404 from './pages/404';
import NotYetAvailable from './pages/NotYetAvailable';
import Login from "./pages/Login";
import Register from "./pages/Register";
import NewSell from "./pages/sell/NewSell";
import ViewSellOrder from "./pages/sell/ViewSellOrder";
// import ViewAllSell from "./pages/sell/ViewAllSell";
// import Buy from "./pages/Buy";
import AccountNotConfirmed from "./pages/AccountNotConfirmed";
import PublicHome from "./pages/PublicHome";
import TermsAndConditions from "./pages/TermsAndConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Contact from "./pages/Contact";
// import AdminHome from "./pages/admin/Home";
import Logout from "./pages/Logout";
import {linkTo} from "./utils/InternalLink";

// HELP CENTER
//import Help from "./pages/help/Help";
import GeneralCategory from "./pages/help/general"
import HowAccountsAreVerified from "./pages/help/general/HowAccountsAreVerified";
import MarqetExplained from "./pages/help/general/MarqetExplained";
import HowProductsAreVerified from "./pages/help/general/HowProductsAreVerified";

const App = () => {
      return (
      <Switch>
            <AppRoute exact path="/" component={PublicHome} />
            <AppRoute exact path={linkTo.termsAndConditions()} component={TermsAndConditions} layout={LayoutDefault} />
            <AppRoute exact path={linkTo.privacyPolicy()} component={PrivacyPolicy} layout={LayoutDefault} />
            <AppRoute exact path={linkTo.contact()} component={Contact} layout={LayoutDefault} />

            <PublicWhenIncognitoRoute exact path={linkTo.login()} component={Login} layout={LayoutFullPageFooterOnly} />
            <PublicWhenIncognitoRoute exact path={linkTo.register()} component={Register} layout={LayoutFullPageFooterOnly} />
            <PublicWhenIncognitoRoute exact path="/confirm-account" component={AccountNotConfirmed} layout={LayoutFullPageFooterOnly} />

            {/*<PrivateRoute exact path="/admin" component={AdminHome} layout={LayoutDefault} />*/}

            <PrivateRoute exact path={linkTo.newSell()} component={NewSell} layout={LayoutDefault} />
            {/*<PrivateRoute exact path={linkTo.viewAllSell()} component={ViewAllSell} layout={LayoutDefault} />*/}
            <PrivateRoute exact path={linkTo.viewAllSell()} component={NotYetAvailable} layout={LayoutDefault} />
            <PrivateRoute exact path={linkTo.viewSell()} component={ViewSellOrder} layout={LayoutDefault} />

            <PrivateRoute exact path={linkTo.viewAllBuy()} component={NotYetAvailable} layout={LayoutDefault} />
            <PrivateRoute exact path={linkTo.viewAllRent()} component={NotYetAvailable} layout={LayoutDefault} />

            <PrivateRoute exact path={linkTo.mainDashBoard()} component={PrivateHome} layout={LayoutDefault} />

            <PrivateRoute exact path={linkTo.helpCenter()} component={GeneralCategory} layout={LayoutDefault} />
            <PrivateRoute exact path={linkTo.helpArticleAccountVerification()} component={HowAccountsAreVerified} layout={LayoutDefault} />
            <PrivateRoute exact path={linkTo.helpArticleProductVerification()} component={HowProductsAreVerified} layout={LayoutDefault} />
            <PrivateRoute exact path={linkTo.helpArticleMarqetExplained()} component={MarqetExplained} layout={LayoutDefault} />

            <PrivateRoute exact path={linkTo.logout()} component={Logout} layout={LayoutDefault} />
            <AppRoute component={Page404} />
    </Switch>
  );
}

export default App;