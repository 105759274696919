import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const propTypes = {
    children: PropTypes.node,
    outerClassName: PropTypes.string,
    innerClassName: PropTypes.string,
    fullHeight: PropTypes.bool,
    title: PropTypes.string,
}

const defaultProps = {
    children: null,
    title: null,
    fullHeight: false,
    outerClassName: '',
    innerClassName: '',
}

const Block = ({
    className,
    children,
    outerClassName,
    innerClassName,
    title,
    fullHeight,
    ...props
}) => {

    const blockClasses = classNames(
        'nk-block',
        fullHeight && 'h-100',
    );

    const outerClasses = classNames(
        'card',
        'card-bordered',
        fullHeight && 'h-100',
        outerClassName
    );

    const innerClasses = classNames(
        'card-inner',
        innerClassName
    );

    return (
        <div className={blockClasses}>
            <div className={outerClasses}>
                {title &&
                <div className="card-inner">
                    <div className="card-title-group">
                        <div className="card-title"><h6 className="title">{title}</h6></div>
                        {/*<div className="card-tools"><a href="/demo6/user-list-regular.html" className="link">Ver Todos</a></div>*/}
                    </div>
                </div>
                }
                <div className={innerClasses}>
                    {children}
                </div>
            </div>
        </div>
    );
}

Block.propTypes = propTypes;
Block.defaultProps = defaultProps;

export default Block;