import React from 'react';
import PropTypes from "prop-types";
import PageTitle from "./sections/partials/PageTitle";
import Block from "./sections/Block";

const propTypes = {
    children: PropTypes.node,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    lastUpdate: PropTypes.string,
}

const defaultProps = {
    children: null,
    subtitle: false,
    lastUpdate: false,
}

const TextOnly = ({title, subtitle, lastUpdate, children}) => {
    return (
        <>
            <PageTitle mainTitle={title}
                description=
                {<>
                    {subtitle&& <p className="lead text-justify">{subtitle}</p>}
                    {lastUpdate && <p className="text-soft ff-italic">Última Atualização: {lastUpdate}</p>}
                </>}
            />
            <Block innerClassName="card-inner-xl">
                <div className="entry text-justify">
                    {children}
                </div>
            </Block>
        </>
    );
}

TextOnly.propTypes = propTypes;
TextOnly.defaultProps = defaultProps;

export default TextOnly;
