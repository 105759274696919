import React from 'react';
import TextOnly from "../components/TextOnly";
import {Link} from "react-router-dom";

const TermsAndConditions = () => {
    return (
        <TextOnly
            title="Termos &amp; Condições"
            subtitle="Por favor, leia o texto que se segue para compreender e tomar conhecimento dos termos e condições."
            lastUpdate="8 de março de 2021"
        >
            <p>
                O presente Site foi criado, pertence e é operado pela Bitten Cookie Unipessoal Lda - doravante Bitten Cookie -, regendo-se a sua utilização por estes Termos de Utilização.
                O utilizador do Site (“Utilizador”) reconhece que ao usar este Site está a aceitar estes Termos de Utilização.
            </p>

            <h3>1. Direitos de Propriedade Intelectual</h3>
            <p>
                A informação contida neste site designadamente o domínio, os logótipos, desenhos, ilustrações, fotografias, filmes, conteúdos, textos, gráficos e documentos anexos – é propriedade exclusiva da Bitten Cookie e de outras entidades e encontra-se protegida pela legislação em vigor.
                O seu download, cópia, modificação, transmissão, distribuição, reprodução ou reenvio, parcial ou integral, para fins comerciais ou outros, sem a autorização prévia e escrita do Bitten Cookie, é expressamente proibido e punível por lei.
                O Utilizador compromete-se a aceder ao site apenas para fins lícitos.
            </p>

            <h3>2. Acesso e Registo</h3>
            <p>
            O acesso a determinados conteúdos desta aplicação é reservado a utilizadores devidamente registados. Algumas funcionalidades do site são pagas. Para ter acesso aos mesmos, o utilizador deverá aceder às áreas de registo, tendo para isso de efectuar o seu registo no site.
            O utilizador concorda em assumir a responsabilidade dos seus dados de login, como o endereço de email e palavra-chave.
            </p>
            <p>
            Ao efectuar o registo o utilizador concorda que:
            </p>
            <ul className="list list-sm list-checked">
                <li>O seu registo é pessoal e intransmissível, não podendo ser utilizado por qualquer outra pessoa</li>
                <li>Não fará nada que possa lesar a Bitten Cookie, nomeadamente aceder a uma área/conta não autorizada e respectiva informação</li>
                <li>Não irá avaliar e testar a vulnerabilidade do sistema e quebrar a segurança instalada</li>
                <li>Não irá enviar emails não solicitados que incluam promoções ou publicidade a produtos ou outros serviços</li>
            </ul>

            <h3>3. Suspensão do acesso</h3>
            <p>
                A Bitten Cookie reserva-se o direito de interromper o acesso ao site pelo período que determine como necessário, por quaisquer razões de ordem técnica, administrativa, de força maior ou outras que possam surgir e não estejam directamente aqui contempladas.
            </p>
            <p>
                Sem prejuízo, a Bitten Cookie não poderá ser responsabilizado por qualquer suspensão ou interrupção de acesso que venha a ocorrer em consequência de factos que não lhe sejam imputáveis ou que sejam imputáveis a título de mera negligência.
            </p>

            <h3>4. Informações e Responsabilidade</h3>
            <p>
                A informação disponibilizada no Site foram objeto de uma análise minuciosa. No entanto, tais informações têm um carácter meramente indicativo, podendo conter erros ou imprecisões.
            </p>
            <p>
            A Bitten Cookie não será responsável por erros que possam ocorrer devido a irregularidades do sistema ou falha (temporária ou permanente) do Site.
            A Bitten Cookie não se responsabilizará por quaisquer danos resultantes da utilização indevida ou da impossibilidade de utilização do Site.
            </p>

            <h3>5. Colocação de anúncios</h3>
            <p>
                A colocação de anúncios no Site ou na Aplicação é tendencialmente gratuita, salvo exepções específicas descritas no momento em que esta colocação é efetuada.
            </p>

            <h3>6. Anúncio</h3>
            <p>
                O anúncio para divulgação de Produtos é preparado pelo utilizador anunciante e é da exclusiva responsabilidade do mesmo, sob as seguintes condições:
            </p>

            <ul className="list list-sm list-checked">
                <li>Em cada anúncio, o Anunciante apenas pode anunciar um Produto e uma transação relativa a esse mesmo Produto</li>
                <li>É necessária e obrigatória a colocação de fotografias que demonstre com clareza o estado do Produto</li>
                <li>O anúncio pode conter algumas informações ocultadas, de forma a permitir o anonimato do Anunciante</li>
            </ul>

            <p>Não é permitida, nomeadamente, a colocação de anúncios respeitantes a:</p>
            <ul className="list list-sm list-checked">
                <li>Qualquer tipo de armas e respetivos acessórios, incluindo armas de fogo ou de ar comprimido, munições, arcos e flechas, bestas e, em geral, qualquer material bélico</li>
                <li>Obras de arte e quaisquer objetos classificados como património de interesse nacional, público ou municipal ou em vias de obter tal classificação</li>
                <li>Drogas, narcóticos e tabaco</li>
                <li>Equipamento para desbloqueio ou visualização de sinais de vídeo codificados</li>
                <li>Fogo-de-artifício e derivados</li>
                <li>Bebidas alcoólicas</li>
                <li>Medicamentos ou testes médicos/farmacêuticos</li>
                <li>Esquemas em pirâmide</li>
                <li>Modchips/Jailbreak</li>
                <li>Programas de afiliados/recompensas ou comissões e/ou parcerias de negócio</li>
                <li>Ofertas não explícitas para ganhar dinheiro/rentabilizar tempo/trabalho a partir de casa</li>
                <li>Órgãos humanos</li>
                <li>Réplicas e contrafações</li>
                <li>Manifestações artesanais (por exemplo, colares) sem o devido registo (Registo Nacional do Artesanato)</li>
                <li>Serviços de tarot, horóscopo e/ou videntes</li>
                <li>Bens digitais - por exemplo, contas de jogos/serviços online, software sem suporte físico, registos/licenças de software, ebooks, vouchers em formato eletrónico</li>
                <li>Vales, vouchers e cartões de qualquer tipo</li>
                <li>Ofertas de timesharing</li>
                <li>Equipamento para jogos de fortuna ou azar</li>
                <li>Bilhetes válidos para o Jardim Zoológico de Lisboa ou outros que não estejam autorizados o seu comércio.</li>
            </ul>
            <p>Sem prejuízo do disposto acima, não é permitido publicar anúncios que violem as regras legais, de bem-estar social, direitos de terceiros e que incluam:</p>
            <ul className="list list-sm list-checked">
                <li>Conteúdo vulgar ou ofensivo</li>
                <li>Conteúdo que viole as regras de etiqueta social</li>
                <li>Conteúdo que promova o ódio e a violência, o racismo, a xenofobia ou conflitos entre nações</li>
                <li>Informação falsa ou dúbia</li>
                <li>Vírus ou qualquer outra tecnologia que possa prejudicar os outros utilizadores</li>
                <li>Conteúdo explícito para adultos e/ou para maiores de 18, nem conteúdo pornográfico</li>
                <li>Conteúdo sexual censurável ou a utilização abusiva por parte dos Anunciantes, que coloquem anúncios deste teor</li>
                <li>Meios de contacto (e-mail, link/URL, telefónico) no título, na descrição ou nas fotografias associadas ao anúncio</li>
                <li>Fotografias genéricas, de catálogo e/ou editadas, que não sejam representativas do Produto que se encontra efetivamente à venda</li>
                <li>Anúncios que não utilizem a língua portuguesa</li>
                <li>Colocação de tags, palavras-chave ou outros termos no título, descrição ou imagens do anúncio que não tenham qualquer relação com o que está a ser anunciado</li>
                <li>Qualquer conteúdo relacionado com serviços que são geralmente repreensivos moralmente, tais como prostituição, esquemas em pirâmide, usura ou outras formas contrárias à lei ou às regras de decência.</li>
            </ul>

            <h3>7. Comissão sobre transações</h3>
            <p>
                A prestação de serviço de divulgação e gestão do processo de anúncio implica o pagamento por parte do utilizador de uma comissão a transação à Bitten Cookie,
                cujo montante é claramente explicitado ao utilizador no momento de colocação do anúncio e com a qual o mesmo concorda.
            </p>
            <p>
                A tabela de comissões está, também, disponível para consulta no Tarifário em vigor.
            </p>
            <p>
                O utilizador pode ser isentado da referida comissão em situações pontuais, resultado de ações comerciais e de marketing desenvolvidas pela Bitten Cookie.
            </p>

            <h3>8. Validade dos Termos de Utilização</h3>
            <p>
                Se alguma parte ou disposição dos presentes Termos de Utilização não for executável ou estiver em conflito com a lei aplicável, a validade das restantes partes ou disposições não será afetada.
            </p>

            <h3>8. Alteração dos Termos de Utilização</h3>
            <p>
                A Bitten Cookie reserva-se o direito de, a qualquer momento, alterar, adicionar, atualizar ou eliminar, parcial ou totalmente os presentes Termos de Utilização.
                O Utilizador deve consultar periodicamente a página destes Termos de Utilização para confirmar se foram efetuadas quaisquer atualizações ou alterações.
            </p>
            <h3>9. Lei aplicável</h3>
            <p>
                Estes Termos de Utilização do Site encontram-se sujeitos à lei portuguesa.
            </p>

            <h3>10. Questões e Contactos</h3>
            <p>
                O Utilizador poderá contactar sobre qualquer questão sobre os presentes Termos de Utilização utilizando uma das diferentes formas de <Link to="/contact">contacto disponíveis na página para o efeito.</Link>
            </p>

        </TextOnly>
    );
}

export default TermsAndConditions;
