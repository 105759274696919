import React from 'react';
import TextOnly from "../../../components/TextOnly";

const HowProductsAreVerified = () => {
    return (
        <TextOnly
            title="Quais são os requisitos para colocar um anúncio de venda?"
            subtitle="Colocar um artigo à venda no Marqet é um processo simples e intuitivo. Ainda assim, há algumas regras"
            lastUpdate="8 de março de 2021"
        >
            <p>
                A segurança dos nossos clientes é a nossa grande prioridade. Para que tenha confiança no que está a comprar, tomamos bastantes
                precauções nos anúncios listados:
            </p>
            <ul className="list list-sm list-checked">
                <li>Impedimos a alteração indevida de informações do produto depois da criação</li>
                <li>Por questões de segurança, não divulgamos publicamente o vendedor de um artigo</li>
                <li>Por questões de segurança, é divulgado o concelho onde o artigo se localiza, mas nunca divulgamos a sua localização exata</li>
                <li>Exigimos pelo menos 3 fotografias do artigo</li>
                <li>Distinguimos claramente produtos novos de usados</li>
                <li>Categorizámos cuidadosamente os produtos para serem fáceis de encontrar</li>
                <li>Pedimos ao anunciante para atualizar / melhorar a descriçã do produto, se assim necessário</li>
            </ul>
        </TextOnly>
    );
}

export default HowProductsAreVerified;
