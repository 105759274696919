import React from 'react';
import {Link} from "react-router-dom";

const TicketItem = ({to, icon, title, subtitle, knowMore}) => {
    return (
        <div className="support-queue-item">
            <Link className="support-queue-link" to={to}>
                <div className="support-queue-media"><em className={icon} /></div>
                <div className="support-queue-body">
                    <div className="support-queue-context"><h5 className="support-queue-title title">{title}</h5>
                        <div className="support-queue-desc">{subtitle}
                        </div>
                    </div>
                    {knowMore && <div className="support-queue-update"><span>Clique para continuar a ler</span></div> }
                </div>
            </Link>
        </div>
    );
}

export default TicketItem;