import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {Link} from "react-router-dom";

const propTypes = {
    children: PropTypes.node,
    mainTitle: PropTypes.string.isRequired,
    upperLink: PropTypes.string,
    description: PropTypes.oneOfType( [PropTypes.string, PropTypes.node]),
}

const defaultProps = {
    children: null,
    description: '',
    upperLink: '',
}

const PageTitle = ({
   className,
   children,
   mainTitle,
   upperLink,
   description,
   ...props
}) => {

    return (
        <div className="nk-block-head nk-block-head-lg">
            { upperLink &&
            <div className="nk-block-head-sub">
                <Link to="/" className="back-to">
                    <em className="icon ni ni-arrow-left" />
                    <span>{upperLink}</span>
                </Link>
            </div>
            }
            <div className="nk-block-between-md g-4">
                <div className="nk-block-head-content">
                    <h3 className="nk-block-title page-title">{mainTitle}</h3>
                    {description && <div className="nk-block-des text-soft"><p>{description}</p></div> }
                </div>
                <div className="nk-block-head-content">
                    <div className="toggle-wrap nk-block-tools-toggle">
                        <a href="#" className="btn btn-icon btn-trigger toggle-expand mr-n1" data-target="pageMenu"><em className="icon ni ni-more-v" /></a>
                        <div className="toggle-expand-content" data-content="pageMenu">
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

PageTitle.propTypes = propTypes;
PageTitle.defaultProps = defaultProps;

export default PageTitle;