import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const propTypes = {
    title: PropTypes.string,
    value: PropTypes.string,
    style: PropTypes.oneOf(['dark', 'gray', 'yellow', 'blue']),
}

const defaultProps = {
    title: '',
    value: '',
    style: 'dark',
}

const StatCard = ({
    title,
    value,
    style,
}) => {

    const cardClasses = classNames(
        'nk-wg-card  card card-bordered', {
            'is-dark': style === 'dark',
            'is-s1': style === 'gray',
            'is-s2': style === 'yellow',
            'is-s3': style === 'blue',
        },
    );

    return (
        <div className={cardClasses}>
            <div className="card-inner">
                <div className="nk-iv-wg2">
                    <div className="nk-iv-wg2-title"><h6 className="title">{title}</h6></div>
                    <div className="nk-iv-wg2-text">
                        <div className="nk-iv-wg2-amount"> {value}
                        {/*<span className="change up"><span className="sign"></span>3.4%</span>*/}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

StatCard.propTypes = propTypes;
StatCard.defaultProps = defaultProps;

export default StatCard;


