import React, {useState} from 'react';
import classNames from "classnames";
import {ErrorMessage, Field} from "formik";
import {Link} from "react-router-dom";

const SensitiveFormField = ({id, title, placeholder, forgotPassword}) => {
    const [show, setShow] = useState(false);

    const passwordClassNames = classNames(
        'form-icon form-icon-right passcode-switch  ', {
            "is-shown": show,
            "is-hidden": !show
        }
    );

    const formClassNames = classNames(
        'form-control form-control-lg  ', {
            "is-shown": show,
            "is-hidden": !show
        }
    );

    const hideField = () => {
        setShow(false);
    }

    const showField = () => {
        setShow(true);
    }

    const type = show ? "text" : "password";

    return (
        <div className="form-group">
            <div className="form-label-group">
                <label className="form-label" htmlFor={id}>{title}</label>
                {forgotPassword && <Link to="/recover-password" className="link link-primary link-sm">
                    Esqueceu-se da password?
                </Link>}
            </div>
            <div className="form-control-wrap">
                <a href="#" className={passwordClassNames} data-target="password" onClick={show ? hideField : showField}>
                    <em className="passcode-icon icon-show icon ni ni-eye" />
                    <em className="passcode-icon icon-hide icon ni ni-eye-off" />
                </a>
                <Field type={type} name={id} className={formClassNames} id={id} placeholder={placeholder} />
                <ErrorMessage name={id} component="div" className="invalid" />
            </div>
        </div>
    );
}

export default SensitiveFormField;