import React from 'react';
import { Redirect } from 'react-router-dom';
import AppRoute from './AppRoute';
import { userDetails } from './AuthService';

const PublicWhenIncognitoRoute = ({ ...rest }) => {
    const user = userDetails();
    if (!user) {
        // not logged in so we can continue
        return (
            <AppRoute {...rest} />
        );
    }

    // if the user is logged-in, we should redirect him to the "main" logged-in page
    return (
        <Redirect to={{ pathname: '/dashboard' }} />
    );
}

export default PublicWhenIncognitoRoute;