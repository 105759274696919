import React from 'react';
import { Redirect } from 'react-router-dom';
import {userDetails, logoutCurrentUser} from '../utils/AuthService';
import {linkTo} from "../utils/InternalLink";

const Logout = () => {
    const user = userDetails();
    if (!user) {
        // not logged in so redirect to login page with the return url
        return (
            <Redirect to={linkTo.login()} />
        );
    }

    logoutCurrentUser();
    return (
        <Redirect to={linkTo.homepage()} />
    );
}

export default Logout;