import React, { useState } from 'react';
import classNames from "classnames";
import UserNotifications from "../UserNotifications";
import {linkTo} from "../../../utils/InternalLink";
import {Link} from "react-router-dom";
import Icon from "../../../components/elements/Icon";

const ToolsMenu = ({user, children}) => {
    const [showNotification, setShowNotification] = useState(false);
    const [showUserMenu, setUserMenu] = useState(false);

    const userInitials = (name) => {
        const name_array = name.split(" ");
        if( name_array.length === 0) return "";
        if( name_array.length >= 2) return name_array[0][0] + name_array[1][0];
        return name_array[0][0];
    };

    const notificationClassNames = classNames(
        'dropdown notification-dropdown', {
            show: showNotification
        }
    );

    const userClassNames = classNames(
        'dropdown user-dropdown', {
            show: showUserMenu
        }
    );

    const openNotification = () => {
        setShowNotification(true);
        setUserMenu(false);
    }

    const closeNotification = () => {
        setShowNotification(false);
    }

    const openUserMenu = () => {
        setShowNotification(false);
        setUserMenu(true);
    }

    const closeUserMenu = () => {
        setUserMenu(false);
    }

    return (
        <div className="nk-header-tools">
            <ul className="nk-quick-nav">
                {/*<li className={notificationClassNames} onClick={showNotification ? closeNotification : openNotification}>*/}
                {/*    <a href="#" className="dropdown-toggle nk-quick-nav-icon" data-toggle="dropdown">*/}
                {/*        <div className="icon-status icon-status-info"><em className="icon ni ni-bell"/></div>*/}
                {/*    </a>*/}
                {/*    {showNotification &&*/}
                {/*    <div className="dropdown-menu dropdown-menu-xl dropdown-menu-right dropdown-menu-s1 show">*/}
                {/*        <div className="dropdown-head">*/}
                {/*            <span className="sub-title nk-dropdown-title">Notifications</span>*/}
                {/*            <a href="#">Mark All as Read</a>*/}
                {/*        </div>*/}
                {/*        <div className="dropdown-body">*/}
                {/*            <UserNotifications />*/}
                {/*        </div>*/}
                {/*        <div className="dropdown-foot center">*/}
                {/*            <a href="#">View All</a>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*    }*/}
                {/*</li>*/}
                <li className="dropdown">
                    <Link to={linkTo.newSell()} className="nk-quick-nav-icon">
                        <Icon icon="plus-round-fill" className="text-success" />
                    </Link>
                </li>
                <li className={userClassNames} onClick={showUserMenu ? closeUserMenu : openUserMenu}>
                    <a href="#" className="dropdown-toggle mr-lg-n1" data-toggle="dropdown">
                        <div className="user-toggle">
                            <div className="user-avatar sm">
                                <Icon icon="user-alt" />
                            </div>
                        </div>
                    </a>
                    {showUserMenu &&
                    <div className="dropdown-menu dropdown-menu-md dropdown-menu-right dropdown-menu-s1 show">
                        <div className="dropdown-inner user-card-wrap bg-lighter d-none d-md-block">
                            <div className="user-card">
                                <div className="user-avatar">
                                    <span>{userInitials(user.name)}</span>
                                </div>
                                <div className="user-info">
                                    <span className="lead-text">{user.name}</span>
                                    <span className="sub-text">{user.email}</span>
                                </div>
                                {/*<div className="user-action">*/}
                                {/*    <a className="btn btn-icon mr-n2" href="html/user-profile-setting.html"><em*/}
                                {/*        className="icon ni ni-setting"/></a>*/}
                                {/*</div>*/}
                            </div>
                        </div>
                        {/*<div className="dropdown-inner">*/}
                        {/*    <ul className="link-list">*/}
                        {/*        <li><a href="html/user-profile-regular.html"><em className="icon ni ni-user-alt"/><span>Perfil</span></a>*/}
                        {/*        </li>*/}
                        {/*        <li><a href="html/user-profile-setting.html"><em*/}
                        {/*            className="icon ni ni-setting-alt"/><span>Preferências</span></a>*/}
                        {/*        </li>*/}
                        {/*        <li><a href="html/user-profile-activity.html"><em*/}
                        {/*            className="icon ni ni-activity-alt"/><span>Login Activity</span></a></li>*/}
                        {/*        <li><a className="dark-switch" href="#"><em*/}
                        {/*            className="icon ni ni-moon"/><span>Modo Noturno</span></a></li>*/}
                        {/*    </ul>*/}
                        {/*</div>*/}
                        <div className="dropdown-inner">
                            <ul className="link-list">
                                <li>
                                    <Link to={linkTo.logout()}>
                                        <Icon icon="signout" />
                                        <span>Sair</span>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    }
                </li>
                <li className="d-lg-none">
                    <a href="#" className="toggle nk-quick-nav-icon mr-n1" data-target="sideNav">
                        <em className="icon ni ni-menu"/></a>
                </li>
            </ul>
        </div>
    );
}

export default ToolsMenu;