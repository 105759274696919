import React from 'react';
import TextOnly from "../../../components/TextOnly";
import {Link} from "react-router-dom";
import {linkTo} from "../../../utils/InternalLink";

const MarqetExplained = () => {
    return (
        <TextOnly
            title="O que é o Marqet?"
            subtitle="A nossa filosofia de trabalho baseia-se em facilitar o processo de compra e venda. Quer comprar ou vender? Diga-nos. Nós tratamos do resto!"
            lastUpdate="8 de março de 2021"
        >
            <p>
                O <span className="text-primary"> Marqet</span> é uma plataforma empresarial para <b>compra e venda</b> de artigos com toda a segurança e comodidade. Através do Marqet, qualquer empresa registada pode colocar produtos à venda
                ou procurar produtos para adquirir.
            </p>
            <p>
                A plataforma <span className="text-primary"> Marqet</span> é operada de forma a garantir a segurança e comodidade dos seus utilizadores, através de:
            </p>

            <ul className="list list-sm list-checked">
                <li>Verificação de todas as empresas presentes na plataforma. Como? <Link to={linkTo.helpArticleAccountVerification()}>Descubra aqui.</Link></li>
                <li>Verificação de todos os anúncios publicados. Como? <Link to={linkTo.helpArticleProductVerification()}>Descubra aqui.</Link></li>
                <li>Disponibilização de um gestor de conta e de um acompanhamento personalizado.</li>
            </ul>

            <h4>Serviços Personalizados</h4>
            <p>
                Para complementar os nossos serviços, fornecemos também serviços adicionais, nomeadamente:
            </p>
            <ul className="list list-sm list-checked">
                <li>Procuramos um comprador para o seu produto.</li>
                <li>Procuramos um produto que queira comprar.</li>
                <li>Ajudámos a avaliar o preço justo para o seu produto.</li>
            </ul>
            <p>
                Para mais informações, entre em contacto com o seu gestor de conta.
            </p>

        </TextOnly>
    );
}

export default MarqetExplained;
