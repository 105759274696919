import React from 'react';
import Category from "../Category";
import {linkTo} from "../../../utils/InternalLink";

const GeneralCategory = ({}) => {
    return (
        <Category title="Olá! Precisa de ajuda?" tickets={[
            {
                title: "O que é o Marqet?",
                subtitle: "O Marqet é uma plataforma empresarial para compra e venda de artigos com toda a segurança e comodidade",
                icon: "icon icon-circle ni ni-info-i bg-gray",
                knowMore: true,
                to: linkTo.helpArticleMarqetExplained()
            },
            {
                title: "Como são verificadas as empresas presentes no Marqet?",
                subtitle: "Todas as contas no Marqet, sejam compradores ou vendedores, são rigorosamente verificadas.",
                icon: "icon icon-circle ni ni-shield-check-fill bg-azure",
                knowMore: true,
                to: linkTo.helpArticleAccountVerification()
            },
            {
                title: "Quais são os requisitos para colocar um anúncio de venda?",
                subtitle: "Colocar um artigo à venda no Marqet é um processo simples e intuitivo. Ainda assim, há algumas regras.",
                icon: "icon icon-circle ni ni-package-fill bg-pink",
                knowMore: true,
                to: linkTo.helpArticleProductVerification()
            },
            {
                title: "Pago alguma comissão por utilizar o Marqet para comprar?",
                subtitle: "Não. O Marqet só cobra comissões aos vendedores.",
                icon: "icon icon-circle ni ni-coins bg-warning-dim",
            },
            {
                title: "Pago alguma comissão por utilizar o Marqet para vender?",
                subtitle: "Sim. O Marqet cobra comissões aos vendedores sob o preço do produto que é vendido.",
                icon: "icon icon-circle ni ni-coins bg-warning-dim",
            },
            {
                title: "Como posso dar sugestões ou reportar problemas?",
                subtitle: "Através do nosso e-mail suporte@marqet.pt",
                icon: "icon icon-circle ni ni-emails-fill bg-indigo",
                knowMore: true,
                to: linkTo.contact()
            }

        ]} />
    );
}

export default GeneralCategory;