import React from 'react';
import TicketItem from "./TicketItem";
import PageTitle from "../../components/sections/partials/PageTitle";
import Block from "../../components/sections/Block";
import ContactUsBanner from "./ContactUsBanner";

const Category = ({
    title,
    tickets
}) => {
    return (
        <>
            <PageTitle mainTitle={title} />
            <Block>
                {tickets.map((ticket) =>
                    <TicketItem title={ticket.title} subtitle={ticket.subtitle} icon={ticket.icon} to={ticket.to} knowMore={ticket.knowMore}/>
                )}
            </Block>
            <ContactUsBanner />
        </>
    );
}

export default Category;