import React, {useState, useEffect} from 'react';
import {Link, useParams} from "react-router-dom";
import HelpBlock from "../../components/sections/Help";
import { getSellByID } from "../../api";
import Page404 from '../404';
import classNames from "classnames";
import Block from "../../components/sections/Block";
import Icon from "../../components/elements/Icon";
import PageTitle from "../../components/sections/partials/PageTitle";
import ProductState from "../../components/product/State";
import Alert from "../../components/elements/Alert";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

const Category = ({name}) => {
    return (
        <li className="btn-group">
            <a className="btn btn-xs btn-light btn-dim"href="#">{name}</a>
            {/*<a className="btn btn-xs btn-icon btn-light btn-dim" href="#">*/}
            {/*    <em className="icon ni ni-cross" />*/}
            {/*</a>*/}
        </li>
    );
}

const SellDescription = ({sell}) => {
    const place = sell.place ? sell.place.place : '';

    return (
      <>
          <ProductState condition={sell.condition} />
          <Icon large icon="map-pin" /> Em <b>{place}</b>
      </>
    );
};

const ViewSellOrder = () => {
    const { id } = useParams();
    const [sell, setSell] = useState({
        title: '',
        tags: [],
        photos: []
    });
    const [notFound, setNotFound] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setSell(await getSellByID(id));
            }
            catch (e) {
                setNotFound(true);
            }
        };

        fetchData();
    }, []);

    if (notFound) return <Page404 />;

    return (
        <>
            <Alert title="Pré-lançamento" description="Ainda não lançamos oficialmente o Marqet. Para já, só você pode ver este anúncio." type="light"/>

            <PageTitle mainTitle={sell.title} description={<SellDescription sell={sell}/>}>
                <ul className="nk-block-tools g-3">
                    {/*<li className="nk-block-tools-opt"><a href="#" className="btn btn-danger"><em className="icon ni ni-trash-fill" /><span>Apagar</span></a></li>*/}
                </ul>
            </PageTitle>
            <div className="nk-block">
                <div className="row g-gs">
                    <div className="col-sm-8">
                        <div className="nk-block">
                            <Carousel
                                showThumbs={false}
                                dynamicHeight
                                swipeable
                                infiniteLoop
                            >
                                {sell.photos.map((photo) =>
                                    <img src={photo.url} />
                                )}
                            </Carousel>
                            <div className="card card-bordered p-2 mt-2" dangerouslySetInnerHTML={{
                                __html: sell.description
                            }}>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <Block outerClassName='card-amount' innerClassName="row">
                            <div className="col-sm-6">
                                <div className="overline-title-alt mb-2">Preço</div>
                                <div className="profile-balance">
                                    <div className="profile-balance-group gx-4">
                                        <div className="profile-balance-sub">
                                            <div className="profile-balance-amount">
                                                <div className="number text-yellow">{sell.price} <small>€</small></div>
                                            </div>
                                            {/*<div className="profile-balance-subtitle">Invested Amount</div>*/}
                                        </div>
                                        <div className="profile-balance-sub">
                                            <span className="profile-balance-plus text-soft"><em className="icon ni ni-plus"/></span>
                                            <div className="profile-balance-amount">
                                                <small className="text-soft">IVA</small>
                                            </div>
                                            {/*<div className="profile-balance-subtitle">Profit Earned</div>*/}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6">

                            </div>
                        </Block>
                        <Block outerClassName='card-amount'>
                            <div className="overline-title-alt mb-2">Categorias</div>
                            <ul className="g-1">
                                {sell.tags.map((tag) =>
                                    <Category name={tag.name} key={tag.id} />
                                )}
                            </ul>
                        </Block>
                        <HelpBlock vertical />
                    </div>
                </div>
            </div>
        </>
    );
}

export default ViewSellOrder;
