import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import {linkTo} from "../../utils/InternalLink";

const Logo = ({
  className,
  ...props
}) => {

  const classes = classNames(
    className
  );

  return (
    <div
      {...props}
      className={classes}
    >
      <h1 className="m-0">
        <Link to={linkTo.mainDashBoard()} className="logo-link text-center h2 marqet-logo">
          Marqet
        </Link>
      </h1>
    </div>
  );
}

export default Logo;