import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const propTypes = {
    condition: PropTypes.string,
}

const defaultProps = {
    condition: '',
}

const ProductState = ({condition}) => {
    const stateBadgeClassname =  classNames(
        'badge',
        'badge-dim',
        'badge-sm',
        'mr-1',
        condition === 'New' && 'badge-outline-success',
        condition === 'Used' && 'badge-outline-warning',
    );

    const stateText = condition === 'New' ? "Novo" : "Usado";

    return (
        <div className={stateBadgeClassname}>{stateText}</div>
    );
}

ProductState.propTypes = propTypes;
ProductState.defaultProps = defaultProps;

export default ProductState;