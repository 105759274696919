const localStorageKey = 'currentUser';

export function userDetails() {
    return JSON.parse(localStorage.getItem(localStorageKey));
}

export function getUser() {
    const maybe_user = userDetails();
    if(maybe_user) {
        return maybe_user.user;
    } else {
        return null;
    }
}

export function logoutCurrentUser() {
    localStorage.removeItem(localStorageKey)
}

export function setCurrentUser(userDetails) {
    return localStorage.setItem(localStorageKey, JSON.stringify(userDetails));
}

export function authToken() {
    // return authorization jwt token
    const currentUser = userDetails();
    if (currentUser && currentUser.token) {
        return currentUser.token;
    } else {
        return null;
    }
}