import React from 'react';
import TextOnly from "../components/TextOnly";
import {Link} from "react-router-dom";

const PrivacyPolicy = () => {
    return (
        <TextOnly
            title="Política de Privacidade"
            subtitle="Por favor, leia o texto que se segue para compreender e tomar conhecimento da forma como os seus dados pessoais serão tratados."
            lastUpdate="21 de janeiro de 2021"
        >
            <p>
                A Bitten Cookie Unipessoal Lda, adiante designado por Bitten Cookie - responsável pelos serviços disponibilizado neste site -
                está empenhada e comprometida no cumprimento da legislação de proteção de dados pessoais, da privacidade e da segurança de informação,
                de forma a proteger os dados pessoais e a privacidade dos utilizadores.

                A presente Política de Privacidade é complementada pelos <Link to="/terms">Termos e Condições de Utilização</Link>.
            </p>

            <h3>1. Recolha e tratamento de dados pessoais</h3>
            <p>
                A utilização de determinadas funcionalidades do Site implica a disponibilização de dados pessoais pelo Utilizador.

                <ul className="list list-sm list-checked">
                    <li>Pedido de contacto</li>
                    <li>Apresentação de interesse em realizar uma compra</li>
                    <li>Apresentação de interesse em realizar uma venda</li>
                    <li>Subscrição de comunicações e informações relevantes</li>
                </ul>
            </p>

            <h3>2. Utilização e Finalidades dos Dados Pessoais Recolhidos</h3>
            <p>
                Os seus dados pessoais são recolhidos e necessários para assegurar a identidade, sendo o fornecimento dos dados solicitados no acto de registo obrigatório para que o se possa utilizar os serviços disponíveis.
                No acto de registo, a informação que disponibiliza tem por objectivo a prestação de um serviço mais adequado às suas características e necessidades e ao envio de informações relacionadas com a utilização dos serviços prestados.
            </p>
            <p>
                Para actualizar as informações fornecidas, sempre que relevante, deve entrar em contacto com o nosso serviço de apoio e suporte.
                Utilizamos os seus dados pessoais para:

                <ul className="list list-sm list-checked">
                    <li>Garantir que os conteúdos sejam apresentados da forma mais eficaz </li>
                    <li>Analisar a utilização do site com vista à obtenção de estatísticas gerais de utilização, para fins de monitorização de tendências, marketing e publicidade</li>
                    <li>Manter o site seguro e protegido</li>
                    <li>Subscrição de comunicações e informações relevantes</li>
                    <li>Pocessar e concluir transacções, bem como para o envio de informações relacionadas, incluindo confirmações de transacção e facturas relativas à aquisição de bens e/ou serviços</li>
                    <li>Comunicar consigo sobre produtos, serviços, ofertas, promoções e eventos ou para envio de newsletters, de acordo com as suas preferências definidas nesse âmbito</li>
                    <li>Para o convidar a participar em inquéritos</li>
                </ul>
            </p>
            <p>
                Não procederemos à venda, aluguer, partilha ou oferta dos dados pessoais fornecidos, excepto nos termos da legislação de proteção de dados pessoais aplicável e nas situações previstas na presente Política de Privacidade.
            </p>

            <h3>3. Conservação de Dados Pessoais</h3>
            <p>
                Os dados pessoais recolhidos são tratados no estrito cumprimento da legislação aplicável. Os dados pessoais serão conservados de acordo com as respetivas
                finalidades e respeitando os prazos legais aplicáveis. Assim, e sempre que não exista uma exigência legal específica, os dados serão armazenados e conservados
                apenas pelo período adequado e na medida do necessário ao âmbito das finalidades para as quais foram recolhidos, exceto se for exercido, dentro dos limites legais,
                o direito de oposição, direito ao apagamento ou caso retire o consentimento.
            </p>
            <h3>4. Segurança</h3>
            <p>
                A Bitten Cookie desenvolve os seus melhores esforços para proteger os dados pessoais dos Utilizadores contra acessos não autorizados.
                Para o efeito utiliza sistemas de segurança, regras e outros procedimentos, de modo a garantir a proteção dos dados pessoais, bem como para prevenir o acesso não autorizado aos dados, o uso impróprio, a sua divulgação, perda ou destruição.
            </p>
            <p>
                É, no entanto, da responsabilidade dos Utilizadores, garantir e assegurar que os dispositivos e equipamentos utilizados para aceder ao Site se encontram adequadamente protegidos contra softwares nocivos, vírus informáticos e worms.
            </p>

            <h3>5. Cookies</h3>
            <p>
                Os "cookies" são pequenos arquivos de texto que identificam o computador do Utilizador no servidor da Bitten Cookie. Os cookies em si não identificam o utilizador individual, apenas o computador utilizado. Os cookies não são usados para recolher dados pessoais.
            </p>
            <p>
                Este site utiliza cookies que servem para ajudar a determinar a utilidade, interesse e o número de acessos ao site, permitindo uma navegação mais rápida e eficiente do mesmo, eliminando a necessidade de introduzir repetidamente as mesmas informações.
                Desta forma, a Bitten Cookie poderá prestar um serviço mais personalizado e à medida dos Utilizadores.
            </p>

            <h3>6. Links para sites de terceiros</h3>
            <p>
                Os links constantes deste Site podem conduzir a outros sites. A Bitten Cookie não se responsabiliza, aprova ou por qualquer forma apoia ou subscreve o conteúdo desses sites, nem dos sites com eles ligados ou neles referidos.
                A Bitten Cookie não se responsabiliza por danos resultantes de vírus que possam infetar o computador ou a rede do Utilizador, ou outros bens, em virtude do acesso ao site da Bitten Cookie com transferência de conteúdos deste para o computador ou rede do Utilizador.
                A presente declaração de privacidade é aplicável unicamente à informação recolhida neste site.
            </p>

            <h3>7. Questões e Contactos</h3>
            <p>
                O Utilizador poderá contactar sobre todas as questões relacionadas com o tratamento dos seus dados pessoais e com o
                exercício dos direitos que lhe são conferidos pela legislação aplicável e, em especial, referidos na presente Política, através
                das diferentes formas de <Link to="/contact"> disponíveis na página para o efeito.</Link>
            </p>

            <h3>8. Alterações à política de privacidade e política de cookies</h3>
            <p>
                A Bitten Cookie reserva-se o direito de, a todo o momento, alterar os presentes termos de tratamento de dados pessoais. Essas alterações serão devidamente publicitadas no Site, com a respectiva data de atualização.
            </p>
        </TextOnly>
    );
}

export default PrivacyPolicy;
