import React from 'react';
import {Link} from "react-router-dom";
import classNames from "classnames";
import {linkTo} from "../../../utils/InternalLink";

const MainMenuItem = ({title, linkTo, children}) => {
    const linkClassNames = classNames(
        'nk-menu-link', {
            'nk-menu-toggle': children !== undefined
        }
    );

    const itemClassNames = classNames(
        'nk-menu-item', {
            'has-sub': children !== undefined
        }
    );

    return (
        <li className={itemClassNames}>
            <Link to={linkTo} className={linkClassNames}>
                <span className="nk-menu-text">{title}</span>
            </Link>
            {children}
        </li>
    );
}

const MainMenu = () => {
    return (
        <div className="nk-header-menu">
            <ul className="nk-menu nk-menu-main">
                <MainMenuItem linkTo={linkTo.mainDashBoard()} title="Início" />
                <MainMenuItem linkTo="/buy" title="Comprar">
                    {/*<ul className="nk-menu-sub">*/}
                    {/*    <li className="nk-menu-item">*/}
                    {/*        <a href="html/apps-messages.html" className="nk-menu-link"><span*/}
                    {/*            className="nk-menu-text">Messages</span></a>*/}
                    {/*    </li>*/}
                    {/*    <li className="nk-menu-item">*/}
                    {/*        <a href="html/apps-inbox.html" className="nk-menu-link"><span className="nk-menu-text">Inbox / Mail</span></a>*/}
                    {/*    </li>*/}
                    {/*    <li className="nk-menu-item">*/}
                    {/*        <a href="html/apps-file-manager.html" className="nk-menu-link"><span className="nk-menu-text">File Manager</span></a>*/}
                    {/*    </li>*/}
                    {/*    <li className="nk-menu-item">*/}
                    {/*        <a href="html/apps-chats.html" className="nk-menu-link"><span className="nk-menu-text">Chats / Messenger</span></a>*/}
                    {/*    </li>*/}
                    {/*    <li className="nk-menu-item">*/}
                    {/*        <a href="html/apps-calendar.html" className="nk-menu-link"><span*/}
                    {/*            className="nk-menu-text">Calendar</span><span*/}
                    {/*            className="nk-menu-badge badge-warning">New</span></a>*/}
                    {/*    </li>*/}
                    {/*    <li className="nk-menu-item">*/}
                    {/*        <a href="html/apps-kanban.html" className="nk-menu-link"><span className="nk-menu-text">Kanban Board</span><span*/}
                    {/*            className="nk-menu-badge badge-warning">New</span></a>*/}
                    {/*    </li>*/}
                    {/*</ul>*/}
                </MainMenuItem>
                <MainMenuItem linkTo={linkTo.viewAllSell()} title="Vender" />
                <MainMenuItem linkTo={linkTo.viewAllRent()} title="Alugar" />
                <MainMenuItem linkTo={linkTo.helpCenter()} title="Suporte" />
            </ul>
        </div>
    );
}

export default MainMenu;