import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const propTypes = {
    icon: PropTypes.string,
    className: PropTypes.string,
    large: PropTypes.bool,
}

const defaultProps = {
    icon: '',
    className: '',
    large: false,
}

const Icon = ({
   className,
   icon,
   large
}) => {
    const classes = classNames(
        'icon',
        'ni',
        `ni-${icon}`,
        large && 'icon-circle-lg',
        className
    );

    return (
        <em className={classes} />
    );
}

Icon.propTypes = propTypes;
Icon.defaultProps = defaultProps;

export default Icon;