import React from 'react';
import PropTypes from 'prop-types';
import classNames from "classnames";

const propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'gray', 'light'])
}

const defaultProps = {
    title: undefined,
    description: undefined,
    type: 'primary',
}

const Alert = ({
   title,
   description,
   type,
   ...props
}) => {
    const classes = classNames(
        'alert alert-icon alert-fill',
        `alert-${type}`
    );

    //alert alert-icon alert-primary

    const iconClasses = classNames(
        "icon ni", {
            "ni-alert-circle": type === 'primary',
            "ni-alert-circle": type === 'secondary',
            "ni-alert-circle": type === 'info',
            "ni-caution-fill": type === 'warning',
            "ni-alert-circle": type === 'gray',
            "ni-alert-circle": type === 'light',
            "ni-check-circle": type === 'success',
            "ni-cross-circle": type === 'danger',
        }
    );

    return (
        <div className={classes} role="alert">
            <em className={iconClasses} />
            <strong>{title}</strong> {description}
        </div>
    );
}

Alert.propTypes = propTypes;
Alert.defaultProps = defaultProps;

export default Alert;