import React, {useEffect, useState} from 'react';
import Block from "../sections/Block";
import { getAllUserSells } from "../../api";
import {Link} from "react-router-dom";
import {linkTo} from "../../utils/InternalLink";

const OwnSellRow = ({sell}) => {
    const date = new Date(sell.created.secs_since_epoch * 1000).toLocaleDateString('pt-PT');

    return (
        <div className="nk-tb-item">
            <div className="nk-tb-col">
                <Link to={linkTo.viewSell(sell.id)}>
                    <div className="user-card">
                        <div className="user-name"><span className="tb-lead">{sell.title}</span></div>
                    </div>
                </Link>
            </div>
            <div className="nk-tb-col">
                <span className="tb-sub tb-amount">{sell.price}<span> EUR</span></span>
            </div>
            <div className="nk-tb-col"><span className="tb-sub">{date}</span></div>
        </div>
    );
}

const OwnSells = () => {
    const [sellList, setSellList] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            setSellList(await getAllUserSells());
        };

        fetchData();
    }, []);

    if( sellList.length === 0 )
    {
        return (
            <Block title="Os meus anúncios" innerClassName="p-0 border-top" fullHeight>
                <div className="user-card user-card-s2 m-3">
                    {/*<div className="user-avatar lg bg-primary"><span>AB</span></div>*/}
                    <div className="user-info">
                        {/*<div className="badge badge-outline-light badge-pill ucap">Investor</div>*/}
                        <h5 className="text-soft">Ainda não tem anúncios...</h5>
                        {/*<span className="sub-text">info@softnio.com</span>*/}
                    </div>
                </div>
            </Block>
        );
    }

    return (
        <Block title="Os meus anúncios" innerClassName="p-0 border-top" fullHeight>
            <div className="nk-tb-list nk-tb-orders">
                <div className="nk-tb-item nk-tb-head">
                    <div className="nk-tb-col"><span>Produto</span></div>
                    <div className="nk-tb-col"><span>Preço</span></div>
                    <div className="nk-tb-col"><span>Publicado</span></div>
                    {/*<div className="nk-tb-col"><span>&nbsp;</span></div>*/}
                </div>
                {sellList.map((row) => <OwnSellRow key={row.id} sell={row} />)}
            </div>
        </Block>
    );
}

export default OwnSells;