import React from 'react';
import {Link} from "react-router-dom";
import {linkTo} from "../../utils/InternalLink";

const Footer = ({...props}) => {
  return (
    <footer
      {...props}
      className="nk-footer"
    >
    <div className="container wide-xl">
      <div className="nk-footer-wrap g-2">
        <div className="nk-footer-copyright"> &copy; 2021 Bitten Cookie.</div>
        <div className="nk-footer-links">
          <ul className="nav nav-sm">
            <li className="nav-item">
              <Link className="nav-link" to={linkTo.contact()}>
                Contacto
              </Link>
              <Link className="nav-link" to={linkTo.termsAndConditions()}>
                Termos &amp; Condições
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to={linkTo.privacyPolicy()}>
                Política de Privacidade
              </Link>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="https://www.livroreclamacoes.pt/inicio" target="_blank" rel="noopener noreferrer">
                Livro de Reclamações
              </a>
            </li>

          </ul>
        </div>
      </div>
    </div>
    </footer>
  );
}

export default Footer;