import React from 'react';
import { Redirect } from 'react-router-dom';
import { isExpired } from "react-jwt";
import AppRoute from './AppRoute';
import {userDetails, logoutCurrentUser} from './AuthService';

const PrivateRoute = ({ ...rest }) => {
    const user = userDetails();
    if (!user) {
        // not logged in so redirect to login page with the return url
        return (
            <Redirect to={{ pathname: '/login' }} />
        );
    }

    if (isExpired(user.token)) {
        // token has expired, the user needs to login again
        logoutCurrentUser();
        return (
            <Redirect to={{ pathname: '/login' }} />
        );
    }

    return (
        <AppRoute {...rest} />
    );
}

export default PrivateRoute;