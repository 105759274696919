import React, {useState} from 'react';
import {Link, useHistory} from "react-router-dom";
import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from "yup";
import {login} from "../api";
import { FormField } from "../components/elements/form/FormField";
import SensitiveFormField from "../components/elements/form/SensitiveFormField";
import Alert from "../components/elements/Alert";
import {setCurrentUser} from "../utils/AuthService";

const Login = () => {

    let history = useHistory();
    const [showError, setShowError] = useState(false);

    const validate = (values) => {
        const errors = {};
        if ( values.email &&
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
        ) {
            errors.email = 'Endereço de e-mail inválido';
        }
        return errors;
    }

    return (
        <>
            <div className="nk-block-head">
                <div className="nk-block-head-content">
                    <h4 className="nk-block-title">Autenticar</h4>
                    <div className="nk-block-des">
                        <p>Introduza as suas credenciais de acesso.</p>
                    </div>
                </div>
            </div>
            <Formik
                initialValues={{
                    email: '',
                    password: '',
                }}
                validate={validate}
                validationSchema={Yup.object({
                    password: Yup.string().required('Obrigatório'),
                    email: Yup.string().email('Endereço de e-mail inválido').required('Obrigatório'),
                })}
                onSubmit={async (values, { setSubmitting }) => {
                    try {
                        let userInfo = await login(values);
                        setCurrentUser(userInfo);
                        setSubmitting(false);
                        history.push("/dashboard");
                    } catch(e) {
                        setSubmitting(false);

                        /// account is still not confirmed
                        if(e.status === 403) {
                            history.push("/confirm-account");
                        } else {
                            setShowError(true);
                        }
                    }
                }}
            >
                {({ isSubmitting }) => (
                    <Form className="is-alter">
                        <FormField
                            id="email"
                            type="email"
                            title="Email"
                            placeholder="Introduza o email que pretende associar"
                        />
                        <SensitiveFormField
                            id="password"
                            title="Password"
                            placeholder="Introduza a password"
                            forgotPassword
                        />
                        <div className="form-group">
                            <button type="submit" disabled={isSubmitting} className="btn btn-lg btn-primary btn-block">Entrar</button>
                        </div>
                    </Form>
                )}
            </Formik>
            {showError &&
                <div className="form-note-s2 text-center pt-4">
                    <Alert title="Erro!" description="Credenciais inválidas" type="danger"/>
                </div>
            }
            <div className="form-note-s2 text-center pt-4"> Ainda não tem uma conta?
                <Link to="/register"> <strong>Registe-se</strong></Link>
            </div>
        </>
    );
}

export default Login;

