import React from 'react';
import {Link} from "react-router-dom";
import {linkTo} from "../../utils/InternalLink";
import Block from "./Block";
import SupportImage from '../../assets/images/support.svg';

const HelpBlock = ({vertical}) => {
    const classname_orientation = vertical ? "nk-help-plain" : "nk-help";

    return (
        <Block>
            <div className={classname_orientation}>
                <div className="nk-help-img">
                    <img src={SupportImage}  alt="support_image"/>
                </div>
                <div className="nk-help-text">
                    <h5>Precisa de ajuda?</h5>
                    <p className="text-soft">Por e-mail ou por telefone, estamos ao seu dispôr.</p>
                </div>
                <div className="nk-help-action">
                    <Link to={linkTo.helpCenter()} className="btn btn-lg btn-outline-primary">Obter ajuda</Link>
                </div>
            </div>
        </Block>
    );
}

export default HelpBlock;
