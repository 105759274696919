import React from 'react';
import Footer from './partials/Footer';
import {Link} from "react-router-dom";

const LayoutFullPageFooterOnly = ({ children }) => (
    <div className="nk-app-root">
        <div className="nk-main ">
            <div className="nk-wrap nk-wrap-nosidebar">
                <div className="nk-content">
                    <div className="nk-block nk-block-middle nk-auth-body wide-xs">
                        <div className="brand-logo pb-4 text-center">
                            <Link to="/" className="logo-link text-center h1 marqet-logo">
                                Marqet
                            </Link>
                        </div>
                        <div className="card card-bordered">
                            <div className="card-inner card-inner-lg">
                                {children}
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </div>
    </div>
);

export default LayoutFullPageFooterOnly;