import React, {useState} from 'react';
import Select from "react-select";
import {useField, useFormikContext} from "formik";
import {ErrorMessage, Field} from "formik";
import makeAnimated from 'react-select/animated';
import RichTextEditor from "react-rte-17";
import classNames from "classnames";

const animatedComponents = makeAnimated();

export const FormField = ({id, type, title, subtitle, placeholder, rightSide}) => (
    <GenericFormField id={id} title={title} subtitle={subtitle} rightSide={rightSide}>
        {rightSide && <div className="form-text-hint"><span className="overline-title">{rightSide}</span></div> }
        <Field type={type} name={id} className="form-control form-control-lg" id={id} placeholder={placeholder} />
    </GenericFormField>
);

export const GenericFormField = ({id, title, subtitle, children, className}) => {
    const formClassname  = classNames(
        "form-control-wrap",
        className
    );

    return (
        <div className="form-group">
            <label className="form-label" htmlFor={id}>{title}
                {subtitle &&
                <div className="nk-kycfm-note">
                    <em className="icon ni ni-info-fill"/>
                    <p>{subtitle}</p>
                </div>
                }
            </label>
            <div className={formClassname}>
                {children}
                <ErrorMessage name={id} component="div" className="invalid form-invalid-message" />
            </div>
        </div>
    );
}

export const TextEditor = ({ id, title }) => {
    const { setFieldValue, setFieldTouched } = useFormikContext();
    const [field, meta] = useField(id);
    const [value, setValue] = useState(RichTextEditor.createValueFromString(field.value, 'html'));

    const config = {
        // Optionally specify the groups to display (displayed in the order listed).
        display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'BLOCK_TYPE_DROPDOWN', 'HISTORY_BUTTONS'],
        INLINE_STYLE_BUTTONS: [
            {label: 'Bold', style: 'BOLD', className: 'custom-css-class'},
            {label: 'Italic', style: 'ITALIC'},
            {label: 'Underline', style: 'UNDERLINE'}
        ],
        BLOCK_TYPE_DROPDOWN: [
            {label: 'Normal', style: 'unstyled'},
            {label: 'Heading Large', style: 'header-one'},
            {label: 'Heading Medium', style: 'header-two'},
            {label: 'Heading Small', style: 'header-three'}
        ],
        BLOCK_TYPE_BUTTONS: [
            {label: 'UL', style: 'unordered-list-item'},
            {label: 'OL', style: 'ordered-list-item'}
        ]
    };

    // Will manually set the value belong to the name props in the Formik form using setField
    function handleOptionChange(selection) {
        setValue(selection);
        setFieldValue(id, selection.toString('html'));
    }

    // Manually updated the touched property for the field in Formik
    function updateBlur() {
        setFieldTouched(id, true);
    }

    return (
        <GenericFormField id={id} title={title} className="text-editor">
            <RichTextEditor
                toolbarConfig={config}
                value={value}
                onBlur={updateBlur}
                onChange={handleOptionChange}
            />
            {meta.touched && meta.error ? (
                <span className="custom-input-error">{meta.error.value}</span>
            ) : null}
        </GenericFormField>
    );
};

export const CustomDropdown = ({ options, isMulti, ...props }) => {
    const { setFieldValue, setFieldTouched } = useFormikContext();
    const [field, meta] = useField({name: props.title});

    // Will manually set the value belong to the name props in the Formik form using setField
    function handleOptionChange(selection) {
        if (isMulti) {
            setFieldValue(props.id, selection.map(x => x.value));
        } else {
            setFieldValue(props.id, selection.value);
        }
    }

    // Manually updated the touched property for the field in Formik
    function updateBlur() {
        setFieldTouched(props.id, true);
    }

    return (
        <GenericFormField id={props.id} title={props.title}>
            <Select
                options={options}
                {...field}
                {...props}
                isMulti={isMulti}
                onBlur={updateBlur}
                onChange={handleOptionChange}
                components={animatedComponents}
                classNamePrefix="react-select"
            />
            {meta.touched && meta.error ? (
                <span className="custom-input-error">{meta.error.value}</span>
            ) : null}
        </GenericFormField>
    );
};
