import React from 'react';

const AccountNotConfirmed = () => {
    return (
        <>
            <div className="align-center flex-wrap flex-md-nowrap g-3 h-100">
                <div className="nk-block-content">
                    <div className="nk-block-content-head">
                        <h4>Estamos a verificar os seus dados...</h4>
                        <span className="sub-text sub-text-sm text-soft">Costumamos ser rápidos!</span>
                    </div>
                    <p>Ao verificar os dados de todas as empresas, estamos a tornar o nosso marketplace mais seguro para todos.</p>
                        <ul className="list list-step">
                            <li className="list-step-done">Registo efetuado com sucesso</li>
                            <li className="list-step-current">Verificação dos dados</li>
                            <li>Conta verificada</li>
                            <li>Pode comprar e vender no nosso marketplace</li>
                        </ul>
                </div>
            </div>
        </>
    );
}

export default AccountNotConfirmed;


