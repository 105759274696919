import { authToken } from "../utils/AuthService";

async function handleResponse(response) {
    if (!response.ok) {
        throw response;
    }

    return await response.json().catch(e => {});
}

function request(method, url, options = {}, withAuth = true) {
    let url_to_fetch = url;
    let requestOptions = options;

    if (typeof options.urlParameters === 'object') {
        url_to_fetch = new URL(url_to_fetch);

        for (let [key, value] of Object.entries(options.urlParameters)) {
            url_to_fetch.searchParams.append(key, value);
        }
    }
    // Set Headers
    requestOptions.headers = new Headers();
    requestOptions.headers.append('Accept', 'application/json');

    if(withAuth) {
        requestOptions.headers.append('Authentication', authToken());
    }

    if (typeof options.data === 'object') {
        requestOptions.headers.append('Content-Type', 'application/json');
        requestOptions.body = JSON.stringify(options.data);
    }

    return fetch(url_to_fetch, { method, ...requestOptions })
        .then(handleResponse);
        // .catch(data => {
        //     console.log(data);
        //     return data;
        // })
}

const http = {
    get: (url, options) => request('GET', url, options),
    post: (url, options) => request('POST', url, options)
};

const api_url = process.env.REACT_APP_MARQET_API_SERVER;

// export function getNews( page = 1) {
//     return http.get(`${api_url}/news`, { urlParameters: { page, limit: 20 }});
// }

export function register(data) {
    return http.post(`${api_url}/auth/register`, { data });
}

export function login(data) {
    return http.post(`${api_url}/auth/login`, { data });
}

export function getPlaces() {
    return http.get(`${api_url}/place`, {}, true);
}

export function getTags() {
    return http.get(`${api_url}/tag`, { }, true);
}

export function getFee(price) {
    return http.get(`${api_url}/fee/${price}`, { }, true);
}

export function send_new_sell(data) {
    return http.post(`${api_url}/order/sell`, { data }, true);
}

export function getSellByID(id) {
    return http.get(`${api_url}/order/sell/${id}`,{ }, true);
}

export function getAllSell() {
    return http.get(`${api_url}/order/sell`,{ }, true);
}

export function getAllUserSells() {
    return http.get(`${api_url}/user/sell`,{ }, true);
}
export function getTemporaryTokenForImageUpload() {
    return http.get(`${api_url}/user/token`,{ }, true);
}
