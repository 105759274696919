import React from 'react';
import { Link } from "react-router-dom";
import { linkTo } from "../../utils/InternalLink";
import Block from "../../components/sections/Block";

const ContactUsBanner = () => {
    return (
        <Block outerClassName="border-primary">
            <div className="nk-cta">
                <div className="nk-cta-block">
                    <div className="nk-cta-img">
                        <em className="icon icon-circle ni ni-msg"/>
                    </div>
                    <div className="nk-cta-text">
                        <p>Não encontra resposta à sua pergunta? Contacte-nos diretamente.</p>
                    </div>
                </div>
                <div className="nk-cta-action">
                    <Link to={linkTo.contact()} className="btn btn-primary">Contactos</Link>
                </div>
            </div>

        </Block>
    );
}

export default ContactUsBanner;
