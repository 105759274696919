import React from 'react';
import TextOnly from "../../../components/TextOnly";
import {Link} from "react-router-dom";
import {linkTo} from "../../../utils/InternalLink";

const HowAccountsAreVerified = () => {
    return (
        <TextOnly
            title="Como são verificadas as empresas presentes no Marqet?"
            subtitle="Compreenda de que forma tornamos o Marqet um local seguro para fazer negócios"
            lastUpdate="8 de março de 2021"
        >
            <p>
                A segurança dos nossos clientes é a nossa grande prioridade. Assim, <b>todas as contas no Marqet, sejam compradores ou vendedores, são rigorosamente verificadas e validadas. </b>
                Esta nossa dedicação permite que o Marqet seja uma plataforma segura e confiável para as empresas fazerem negócios, com a seriedade e autenticidade que o ambiente empresarial requer.
            </p>
            <p>
                A verificação de uma conta é um processo que levamos muito a sério e que consiste em vários passos:
            </p>

            <h4>Verificação do e-mail de registo</h4>
            <p>
                Verificamos que o e-mail de registo utiliza um domínio conhecido da empresa (por exemplo, @asuaempresa.pt) e cruzamos a informação pública da empresa com a informação do serviço público DNS.pt. Para
                além disso, confirmámos que o utilizador tem acesso ao referido e-mail, através da recepção e respectiva resposta de um e-mail de verificação nosso.
            </p>
            <p>
                Só em raras excepções aceitamos registos com domínios de registo livre (@gmail, @sapo, @hotmail, entre outros), sempre sob a investigação de que a empresa o faz por não possuir domínio próprio.
            </p>

            <h4>Contacto Telefónico</h4>
            <p>
                Validamos telefonicamente os dados submetidos no formulário registo, cruzando-os com outras fontes de informação.
            </p>

            <h3>Tem alguma questão?</h3>
            <p>
                Tem alguma preocupação acerca do nosso processo de verificação de contas? Gostaria de ver alguma dúvida esclarecida?
                <Link to={linkTo.contact()}> Contacte-nos!</Link>
            </p>
        </TextOnly>
    );
}

export default HowAccountsAreVerified;
