import React, {useState} from 'react';
import { Link, useHistory } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { register } from '../api';
import Alert from "../components/elements/Alert";
import { FormField } from "../components/elements/form/FormField";
import SensitiveFormField from "../components/elements/form/SensitiveFormField";
import {linkTo} from "../utils/InternalLink";

const Register = () => {
    let history = useHistory();
    const [showError, setShowError] = useState(false);

    const validate = (values) => {
        const errors = {};
        if ( values.email &&
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
        ) {
            errors.email = 'Endereço de e-mail inválido';
        }
        return errors;
    }

    return (
        <>
            <div className="nk-block-head">
                <div className="nk-block-head-content">
                    <h4 className="nk-block-title">Registar</h4>

                    <div className="nk-block-des">
                        <p>Crie uma nova conta empresarial. É gratuito.</p>
                    </div>
                </div>
            </div>
            <Formik
                initialValues={{
                    nipc: '',
                    company: '',
                    email: '',
                    password: '',
                    confirm_password: '',
                    agreement: false,
                    person_name: '',
                    mobile: '',
                }}
                validate={validate}
                validationSchema={Yup.object({
                    person_name: Yup.string()
                        .min(1, "Tem que ter, pelo menos, 1 caracteres")
                        .max(150, 'Não pode ter mais que 150 caracteres')
                        .required('Obrigatório'),
                    nipc: Yup.number()
                        .integer()
                        .min(500000000, 'Necessita de um NIPC de empresa válido')
                        .max(999999999, 'Necessita de um NIPC de empresa válido')
                        .required('Obrigatório'),
                    mobile: Yup.number()
                        .positive()
                        .integer()
                        .required('Obrigatório'),
                    password: Yup.string()
                        .min(8, "Tem que ter, pelo menos, 8 caracteres")
                        .max(150, 'Não pode ter mais que 150 caracteres')
                        .required('Obrigatório'),
                    confirm_password: Yup.string()
                        .oneOf([Yup.ref('password')], 'As passwords não coincidem').required('Obrigatório'),
                    company: Yup.string()
                        .max(100, 'Não pode ter mais que 100 caracteres')
                        .required('Obrigatório'),
                    email: Yup.string()
                        .email('Endereço de e-mail inválido')
                        .required('Obrigatório'),
                    agreement: Yup.boolean()
                        .required('Obrigatório')
                        .oneOf([true], 'É necessário aceitar os nossos termos e condições.'),
                })}
                onSubmit={async (values, { setSubmitting }) => {
                    try {
                        await register(values);
                        setSubmitting(false);
                        history.push("/confirm-account");
                    } catch(e) {
                        // todo: handle error
                        //console.log("Error a criar conta!");
                        setSubmitting(false);
                        setShowError(true);
                    }
                }}
            >
                {({ isSubmitting }) => (
                    <Form className="is-alter">
                        <FormField
                            id="company"
                            type="text"
                            title="Designação da Empresa"
                            placeholder="Introduza o nome da empresa"
                        />
                        <FormField
                            id="nipc"
                            type="text"
                            title="NIPC"
                            placeholder="Introduza o contribuinte fiscal da empresa"
                        />
                        <FormField
                            id="person_name"
                            type="text"
                            title="Nome do Responsável"
                            placeholder="Introduza o nome do responsável por esta conta"
                        />
                        <FormField
                            id="mobile"
                            type="text"
                            title="Contacto Telefónico"
                            placeholder="Introduza o contacto do responsável por esta conta"
                        />
                        <FormField
                            id="email"
                            type="email"
                            title="Email"
                            placeholder="Introduza o email que pretende associar"
                        />
                        <SensitiveFormField
                            id="password"
                            title="Password"
                            placeholder="Introduza a password"
                        />
                        <SensitiveFormField
                            id="confirm_password"
                            title="Password"
                            placeholder="Confirme a password"
                        />
                        <div className="form-group">
                            <div className="custom-control custom-control-xs custom-checkbox">
                                <Field type="checkbox" name="agreement" className="custom-control-input" id="checkbox" />
                                <label className="custom-control-label" htmlFor="checkbox">
                                    Li e concordo com os
                                    <Link to={linkTo.termsAndConditions()} target="_blank"> Termos &amp; Condições</Link> e com a <Link to={linkTo.privacyPolicy()} target="_blank"> Política de Privacidade</Link>
                                </label>
                                <ErrorMessage name="agreement" component="div" className="invalid" />
                            </div>
                        </div>
                        <div className="form-group">
                            <button type="submit" disabled={isSubmitting} className="btn btn-lg btn-primary btn-block">Criar conta</button>
                        </div>
                    </Form>
                )}
            </Formik>
            {showError &&
                <div className="form-note-s2 text-center pt-4">
                    <Alert title="Erro!" description="Não pudemos criar a sua conta" type="danger"/>
                </div>
            }
            <div className="form-note-s2 text-center pt-4"> Já tem uma conta?
                <Link to="/login"><strong> Autentique-se</strong></Link>
            </div>
        </>
    );
}

export default Register;
