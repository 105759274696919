import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Dropzone from 'react-dropzone-uploader';
import { getTemporaryTokenForImageUpload } from "../../api";
import { FieldArray, ErrorMessage } from 'formik';

const propTypes = {
    className: PropTypes.string,
}

const defaultProps = {
    icon: '',
    className: '',
}

const S3Dropzone = ({
    fieldName,
    ...props
}) => {
    const getUploadParams = async ({ file, meta }) => {
        const {photo_id, signed_url} = await getTemporaryTokenForImageUpload();
        return { method: 'put', body: file, meta: { photo_id }, url: signed_url }
    }

    const handleChangeStatus = (push) => ({ meta, remove }, status, all) => {
        switch (status) {
            case 'error_file_size':
            case 'rejected_file_type':
            case 'error_validation':
            case 'exception_upload':
            case 'error_upload':
            case 'error_upload_params':
            case 'rejected_max_files':
                remove();
                break;
            case 'done':
                push(meta.photo_id);
                break;
        }
    }

    const Preview = ({ meta }) => {
        const { name, percent, status, previewUrl, size } = meta;
        const isUploading = ['preparing', 'getting_upload_params', 'uploading'].includes(status);
        const isOk = ['done'].includes(status);
        const isError = ['rejected_file_type', 'error_file_size', 'error_upload_params', 'rejected_max_files', 'error_validation', 'exception_upload', 'error_upload'].includes(status);

        const sizeInMB = Number(size / 1024 / 1024).toFixed(2);

        // 'ready', 'started', , 'exception_upload', 'aborted', 'restarted', 'removed', 'headers_received'

        const classes = classNames(
            'dz-image-preview',
            'dz-preview',
            isUploading && 'dz-processing',
            isError && 'dz-error',
            isOk && 'dz-success',
        );

        return (
        <div className={classes}>
            <div className="dz-image">
                <img data-dz-thumbnail="as" alt={name} src={previewUrl} />
            </div>
            <div className="dz-details">
                <div className="dz-size"><span data-dz-size={sizeInMB}><strong>{sizeInMB}</strong> MB</span></div>
                <div className="dz-filename"><span data-dz-name={name}>{name}</span></div>
            </div>
            {isUploading && <div className="dz-progress">
                <span className="dz-upload" data-dz-uploadprogress={percent} style={{width: percent }} />
            </div>}
            {isOk && <div className="dz-success-mark">
                <svg className="ft-green-tick" xmlns="http://www.w3.org/2000/svg" height="100" width="100"
                     viewBox="0 0 48 48" aria-hidden="true">
                    <circle className="circle" fill="#5bb543" cx="24" cy="24" r="22"/>
                    <path className="tick" fill="none" stroke="#FFF" strokeWidth="6" strokeLinecap="round"
                          strokeLinejoin="round" strokeMiterlimit="10" d="M14 27l5.917 4.917L34 17"/>
                </svg>
            </div>}
            {isError && <div className="dz-error-message"><span data-dz-errormessage="">Erro!</span></div> }
            {isError && <div className="dz-error-mark">
                <svg width="54px" height="54px" viewBox="0 0 54 54" version="1.1" xmlns="http://www.w3.org/2000/svg"><title>Erro!</title>
                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                        <g stroke="#747474" strokeOpacity="0.198794158" fill="red" fillOpacity="0.816519475">
                            <h d="M32.6568542,29 L38.3106978,23.3461564 C39.8771021,21.7797521 39.8758057,19.2483887 38.3137085,17.6862915 C36.7547899,16.1273729 34.2176035,16.1255422 32.6538436,17.6893022 L27,23.3431458 L21.3461564,17.6893022 C19.7823965,16.1255422 17.2452101,16.1273729 15.6862915,17.6862915 C14.1241943,19.2483887 14.1228979,21.7797521 15.6893022,23.3461564 L21.3431458,29 L15.6893022,34.6538436 C14.1228979,36.2202479 14.1241943,38.7516113 15.6862915,40.3137085 C17.2452101,41.8726271 19.7823965,41.8744578 21.3461564,40.3106978 L27,34.6568542 L32.6538436,40.3106978 C34.2176035,41.8744578 36.7547899,41.8726271 38.3137085,40.3137085 C39.8758057,38.7516113 39.8771021,36.2202479 38.3106978,34.6538436 L32.6568542,29 Z M27,53 C41.3594035,53 53,41.3594035 53,27 C53,12.6405965 41.3594035,1 27,1 C12.6405965,1 1,12.6405965 1,27 C1,41.3594035 12.6405965,53 27,53 Z" />
                        </g>
                    </g>
                </svg>
            </div>}
        </div>
        );
    }

    const Input = ({ accept, onFiles, files, getFilesFromEvent }) => {
        const text = files.length > 0 ? 'Adicionar mais' : 'Escolher ficheiros'

        return (
            <label>
                <span className="dz-message-text">Arraste para aqui</span>
                <span className="dz-message-or">ou</span>
                <div className="btn btn-primary">{text}</div>
                <input
                    style={{ display: 'none' }}
                    type="file"
                    accept={accept}
                    multiple
                    onChange={async e => {
                        const target = e.target
                        const chosenFiles = await getFilesFromEvent(e)
                        onFiles(chosenFiles)
                        target.value = null
                    }}
                />
            </label>
        )
    }

    const Layout = ({ input, previews, dropzoneProps, files, extra: { maxFiles } }) => {
        return (
            <div className="nk-kycfm-upload-box">
                <div {...dropzoneProps} className="upload-zone dropzone dz-clickable">
                    {previews}
                    <div className="dz-message">
                        {files.length < maxFiles && input}
                    </div>
                </div>
            </div>
        );
    }

    return (
        <>
            <FieldArray
                name={fieldName}
                render={({ push }) => (
                    <Dropzone
                        accept='image/jpeg, image/png'
                        maxSizeBytes={3*1024*1024}
                        maxFiles={10}
                        getUploadParams={getUploadParams}
                        onChangeStatus={handleChangeStatus(push)}
                        PreviewComponent={Preview}
                        LayoutComponent={Layout}
                        InputComponent={Input}
                        canCancel={false}
                    />
                )}
            />
            <ErrorMessage name={fieldName} component="div" className="invalid form-invalid-message" />
        </>
    );
}

S3Dropzone.propTypes = propTypes;
S3Dropzone.defaultProps = defaultProps;

export default S3Dropzone;
