import React from 'react';
import Logo from '../../components/elements/Logo';
import MainMenu from "./header/MainMenu";
import ToolsMenu from "./header/ToolsMenu";
import {getUser} from "../../utils/AuthService";

const Header = () => {
    const user = getUser();

    return (
      <div className="nk-header nk-header-fixed is-dark">
        <div className="container-lg wide-xl">
          <div className="nk-header-wrap">
            <div className="nk-header-brand">
                <Logo />
            </div>
              {user && <MainMenu /> }
              {user && <ToolsMenu user={user} /> }
          </div>
        </div>
      </div>
  );
}

export default Header;